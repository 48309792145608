////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {Task} from "../dataModel/Task";
import Selector from "./Selector";
import {FileDestinationNamingType} from "../clients/Classes";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {CanArchive} from "../Utility";
import {BlueButton, FlexRow, FlexRowCentered} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import {InfoPopover} from "@adsk/alloy-react-tooltip";
import Modal from "@adsk/alloy-react-modal";
import zipExample from '../assets/ZipExample.png';
import {ClientProvider} from "../clients/ClientProvider";
import {ROLE_ID_COPY_ZIP} from "../Constants";

const authService = ClientProvider.AuthService;

const SettingsOptions = ({task}: {
  task: Task | undefined
}) => {
  const [naming, setNaming] = useState(task?.ExportDestinationNaming);
  const [email, setEmail] = useState(task?.EmailOnCompletion ?? false);
  const [attachCsv, setAttachCsv] = useState(task?.AttachCsv ?? false);
  const [maintainFileStructure, setMaintainFileStructure] = useState(task?.DoNotMaintainFolderStructure !== true);
  const [archive, setArchive] = useState(task?.Archive ?? false);
  const [unzipRvt, setUnzipRvt] = useState(!task?.CopyCompositeFiles);
  const [showUnzipWarning, setShowUnzipWarning] = useState(false);

  const options: DefaultItem[] = [
    {value: FileDestinationNamingType.Overwrite, label: 'Overwrite Existing Files'},
    {value: FileDestinationNamingType.AppendTimestamp, label: 'Append Time Stamp'},
  ];

  function namingChanged(value: DefaultItem | null | undefined): void {
    if (task == null) {
      return;
    }
    task.ExportDestinationNaming = value == null
      ? FileDestinationNamingType.None
      : value.value as FileDestinationNamingType;
    setNaming(task.ExportDestinationNaming);
  }

  function emailChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.EmailOnCompletion = state === true;
    setEmail(state === true);
  }

  function csvChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.AttachCsv = state === true;
    setAttachCsv(state === true);
  }

  function maintainFileStructureChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.DoNotMaintainFolderStructure = state !== true;
    setMaintainFileStructure(state === true);
  }

  function archiveChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.Archive = state === true;
    if (task.Archive) {
      task.DoNotMaintainFolderStructure = false;
      setMaintainFileStructure(true);
    }
    setArchive(state === true);
  }

  function unzipChanging(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    if (state === true) {
      setShowUnzipWarning(true);
    } else {
      setUnzipRvt(false);
      console.log('setting');
      task.CopyCompositeFiles = true;
    }
  }

  function unzipWarningAccepted(): void {
    setShowUnzipWarning(false);
    if (task == null) {
      return;
    }

    task.CopyCompositeFiles = false;
    setUnzipRvt(true);
  }

  function disableArchive(): boolean {
    return task != null && !CanArchive(task);
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Options</h2>
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={maintainFileStructure}
                  disabled={archive}
                  onChange={maintainFileStructureChanged}/>
        <label style={{marginLeft: '0.5em'}}>Maintain folder structure at destination</label>
      </FlexRowCentered>
      <p style={Theme.typography.bodyMedium}>File naming</p>
      <Selector items={options}
                selected={options.find(o => o.value === naming)}
                onSelectionChange={namingChanged}/>
      {
        authService.CurrentUser?.roles?.includes(ROLE_ID_COPY_ZIP) &&
        <FlexRowCentered style={{marginTop: '2em'}}>
          <Checkbox checked={unzipRvt}
                    onChange={unzipChanging}/>
          <label style={{marginLeft: '0.5em'}}>Unzip RVT Container Files</label>
          <InfoPopover
            message={"The default setting will copy only the Revit files within each folder. When you enable this option, the destination folder will also include any models linked into the host model."}
            style={{marginLeft: '1em'}}/>
        </FlexRowCentered>
      }
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={email}
                  onChange={emailChanged}/>
        <label style={{marginLeft: '0.5em'}}>Email me when each run of this task completes</label>
      </FlexRowCentered>
      <FlexRowCentered style={{marginTop: '0.5em', marginLeft: '2em'}}>
        <Checkbox disabled={!task?.EmailOnCompletion}
                  checked={attachCsv}
                  onChange={csvChanged}/>
        <label style={{marginLeft: '0.5em'}}>Attach the CSV transfer log</label>
      </FlexRowCentered>
      <div style={{marginTop: '2em'}}>
        {
          !disableArchive() &&
          <FlexRowCentered>
            <Checkbox checked={archive}
                      onChange={archiveChanged}/>
            <label style={{marginLeft: '0.5em'}}>Archive source files</label>
          </FlexRowCentered>
        }
      </div>
      {
        // TODO: This blocks the UI but only when debugging locally for some reason.  To fix this for local debug use showUnzipWarning && instead of open property.
      }
      <Modal open={showUnzipWarning}>
        <Modal.Header>Unzipping Container Files</Modal.Header>
        <Modal.Body>
          <FlexRow>
            <div>
              <p style={Theme.typography.bodyMedium}>The default setting will copy only the Revit files within each
                folder.</p>
              <p style={Theme.typography.bodyMedium}>When you enable this option, the destination folder will also
                include any models linked into the
                host model.</p>
              <p style={Theme.typography.bodyMedium}><a href={'https://revolutiondesign.biz'} target={"_blank"}>More
                Information</a></p>
            </div>
            <img alt={'Zip example'} src={zipExample} style={{maxHeight: 270, margin: '1em'}}/>
          </FlexRow>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton style={{marginLeft: '1em'}} onClick={unzipWarningAccepted}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SettingsOptions;