// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  CreateJobRequest,
  DeckardJobOptions,
  DirectoryDto,
  DirectorySelectionOptions,
  EditJobRequest,
  EditScheduleRequest,
  EmailPreferences,
  FileDto,
  JobRun,
  JobScheduleType
} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {Task} from "../dataModel/Task";
import {TaskTranslator} from "../dataModel/Translators/TaskTranslator";
import {CronTranslator} from "../dataModel/Translators/CronTranslator";
import {FileStructureTranslator} from "../dataModel/Translators/FileStructureTranslator";
import {ZipTaskSetting} from "../dataModel/ZipTaskSetting";
import {FileUI} from "../dataModel/FileUI";
import {GetUnique, IncludeZip, NameOf} from "../Utility";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {ServiceBase} from "./ServiceBase";
import {IV2Client} from "../clients/V2Client";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IClient} from "../clients/Client";
import {JobPaginatedResult} from "../clients/V2Classes";

export class TaskService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  GetTasks(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    loadFilesAndFolders?: boolean,
    loadItems?: boolean
  ): Promise<JobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listJobs(t, limit, loadFilesAndFolders, loadItems), paginationToken)
        .then(r => new JobPaginatedResult(r))
    );
  }

  GetRemainingTasks(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    loadFilesAndFolders?: boolean,
    loadItems?: boolean,
    cancelToken?: CancellationToken | undefined
  ): Promise<JobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(
        token => this._v2Client.listJobs(token, limit, loadFilesAndFolders, loadItems), paginationToken, cancelToken)
        .then(jobs => new JobPaginatedResult(jobs))
    );
  }

  CreateTask(command: CreateJobRequest): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.createJob(command)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  GetTask(id: string): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.getJob(id)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  RunTask(id: string): Promise<JobRun> {
    return this.TryPromiseWithCatch(() =>
      this._client.startJob(id)
    );
  }

  GetTaskUpdate(original: Task, updated: Task): EditJobRequest {
    const scheduleNew = TaskTranslator.GetScheduleFromTask(updated);

    let scheduleEdit: EditScheduleRequest | undefined = undefined;

    let scheduleType: JobScheduleType = JobScheduleType.None;

    switch (updated.Trigger) {
      case 'OnceNow':
        scheduleType = JobScheduleType.OnceNow;
        break;
      case 'OnceLater':
        scheduleType = JobScheduleType.OnceLater;
        break;
      case 'Recurring':
        scheduleType = JobScheduleType.Recurring;
        break;
      case 'OnPublish':
        scheduleType = JobScheduleType.OnPublish;
        break;
    }

    if (scheduleNew != null) {
      scheduleEdit = new EditScheduleRequest({
        cronString: updated.RecurrenceSettings == null ? undefined : CronTranslator.GetCronString(updated.RecurrenceSettings),
        startDate: scheduleNew.startDate,
        endDate: scheduleNew.endDate,
        clearEndDate: false,
        clearStartDate: false,
        maxRuns: scheduleNew.maxRuns
      });
    }

    let options: DeckardJobOptions | undefined;
    const hasContentUpdates = this.HasUpdatedDirectories(original, updated)
      || this.HasUpdatedFiles(original, updated);

    if (original.EmailOnCompletion !== updated.EmailOnCompletion
      || original.ExportLocationType !== updated.ExportLocationType
      || original.ExportDestinationNaming !== updated.ExportDestinationNaming
      || original.ExportDirectory?.Id !== updated.ExportDirectory?.Id
      || original.AttachCsv !== updated.AttachCsv
      || original.CopyCompositeFiles !== updated.CopyCompositeFiles
      || hasContentUpdates) {
      const directoryOptions: { [key: string]: DirectorySelectionOptions } = {};
      if (updated.Directories != null) {
        updated.Directories
          .filter(d => d.IsDynamic)
          .forEach(d => directoryOptions[d.Id] = new DirectorySelectionOptions({
            recursive: d.IsRecursive,
            copyCurrentFolder: d.IncludeThisFolder
          }));
      }

      options = new DeckardJobOptions({
        locationType: updated.ExportLocationType,
        destinationNamingType: updated.ExportDestinationNaming,
        extractDirectory: updated.ExportDirectory == null
          ? undefined
          : FileStructureTranslator.GetApiDirectoryDto(updated.ExportDirectory),
        emailPreferences: new EmailPreferences({
          emailOnCompletion: updated.EmailOnCompletion,
          attachExportFiles: updated.AttachCsv
        }),
        singleFiles: this.GetSingleFiles(updated),
        doNotMaintainFolderStructure: updated.DoNotMaintainFolderStructure,
        archive: updated.Archive,
        directorySelectionOptions: directoryOptions,
        copyCompositeFiles: updated.CopyCompositeFiles
      });
    }

    const originalZips = original.Files
      .filter(f => f instanceof ZipTaskSetting) as ZipTaskSetting[];
    const originalFiles = original.Files
      .filter(f => f instanceof FileUI)
      .concat(originalZips.map(z => z.File)) as FileUI[];

    const updatedZips = updated.Files
      .filter(f => f instanceof ZipTaskSetting) as ZipTaskSetting[];
    const updatedFiles = updated.Files
      .filter(f => f instanceof FileUI)
      .concat(updatedZips.map(z => z.File)) as FileUI[];

    const addFiles: FileDto[] = GetUnique<FileUI>(originalFiles, updatedFiles, NameOf<FileUI>('Id'))
      .map(f => FileStructureTranslator.GetApiFileDto(f));
    const removeFiles: FileDto[] = GetUnique<FileUI>(updatedFiles, originalFiles, NameOf<FileUI>('Id'))
      .map(f => FileStructureTranslator.GetApiFileDto(f));

    const directoriesOriginalDynamic = original.Directories
      .filter(d => d.IsDynamic);
    const directoriesOriginalNonDynamic = original.Directories
      .filter(d => !d.IsDynamic);
    const directoriesUpdatedDynamic = updated.Directories
      .filter(d => d.IsDynamic);
    const directoriesUpdatedNonDynamic = updated.Directories
      .filter(d => !d.IsDynamic);

    const addDirectories: DirectoryDto[] =
      GetUnique<DirectoryUI>(directoriesOriginalDynamic, directoriesUpdatedDynamic, NameOf<DirectoryUI>('Id'))
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d));
    const removeDirectories: DirectoryDto[] =
      GetUnique<DirectoryUI>(directoriesUpdatedDynamic, directoriesOriginalDynamic, NameOf<DirectoryUI>('Id'))
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d));

    const addNonDynamic: DirectoryDto[] =
      GetUnique<DirectoryUI>(directoriesOriginalNonDynamic, directoriesUpdatedNonDynamic, NameOf<DirectoryUI>('Id'))
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d));
    const removeNonDynamic: DirectoryDto[] =
      GetUnique<DirectoryUI>(directoriesUpdatedNonDynamic, directoriesOriginalNonDynamic, NameOf<DirectoryUI>('Id'))
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d));

    return new EditJobRequest({
      name: original.Name === updated.Name ? undefined : updated.Name,
      jobScheduleType: scheduleType,
      editSchedule: scheduleEdit,
      options: options,
      runOnceDateTime: scheduleType === JobScheduleType.OnceLater ? updated.StartDate : undefined,
      addFiles: addFiles.length > 0 ? addFiles : undefined,
      removeFiles: removeFiles.length > 0 ? removeFiles : undefined,
      addDirectories: addDirectories.length > 0 ? addDirectories : undefined,
      removeDirectories: removeDirectories.length > 0 ? removeDirectories : undefined,
      addNonDynamicDirectories: addNonDynamic,
      removeNonDynamicDirectories: removeNonDynamic,
    });
  }

  UpdateTask(task: Task, update: EditJobRequest): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.editJob(task.Id!, update)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  DeleteTask(task: Task): Promise<boolean> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteJob(task.Id!).then(() => true)
    );
  }

  ToggleTaskPause(task: Task): Promise<boolean> {
    const command: EditJobRequest = new EditJobRequest({
      pauseJob: !task.IsPaused
    });

    return this.TryPromiseWithCatch(() =>
      this._client.editJob(task.Id!, command)
        .then(_job => {
          task.IsPaused = _job.schedule != null && _job.schedule.isPaused!;
          task.RawStatus = _job.status;
          return true;
        })
    );
  }

  GetSingleFiles(task: Task): { [key: string]: string[] } | undefined {
    if (task.Files == null || task.Files.length === 0) {
      return undefined;
    }

    const value: { [key: string]: string[] } = {};
    for (const entry of task.Files) {
      if (!(entry instanceof ZipTaskSetting)) {
        continue;
      }

      // Skip if the parent is included by itself
      if (!IncludeZip(entry, task)) {
        continue;
      }

      value[entry.File.Id] = entry.Zips.flatMap(z => z.Name);
    }

    return value;
  }

  private HasUpdatedDirectories(original: Task, updated: Task): boolean {
    if (original.Directories == null) {
      return updated.Directories != null;
    }

    if (updated.Directories == null) {
      // If we got here original is non-null so there are changes
      return true;
    }

    if (original.Directories.length !== updated.Directories.length) {
      return true;
    }

    original.Directories.forEach(d => {
      const found = updated.Directories.find(u => u.Id === d.Id);

      if (
        found == null
        || d.IncludeThisFolder !== found.IncludeThisFolder
        || d.IsDynamic !== found.IsDynamic
        || d.IsRecursive !== found.IsRecursive
      ) {
        return true;
      }
    });

    return false;
  }

  private HasUpdatedFiles(original: Task, updated: Task): boolean {
    if (original.Files == null) {
      return updated.Files != null;
    }

    if (updated.Files == null) {
      // If we got here original is non-null so there are changes
      return true;
    }

    if (original.Files.length !== updated.Files.length) {
      return true;
    }

    original.Files.forEach(d => {
      if (d instanceof FileUI) {
        const found = updated.Files.find(u => u instanceof FileUI && u.Id === d.Id);

        if (found == null) {
          return true;
        }
      } else {
        const found = updated.Files
          .filter(f => f instanceof ZipTaskSetting && IncludeZip(f, updated))
          .find(u => u instanceof ZipTaskSetting && u.File.Id === d.File.Id) as ZipTaskSetting;

        if (found == null) {
          return true;
        }

        d.Zips.forEach(originalZip => {
          const updatedZip = found.Zips.find(z => z.Name === originalZip.Name);
          if (updatedZip == null) {
            return true;
          }
        });
      }
    });

    return false;
  }
}